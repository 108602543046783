<template>
  <v-row>
    <v-col>
      <DataValue
        label="Lead Generator"
        :value="fromName"
        :user-id="fromId"
        icon="account-circle"
        :extra-info="$helpers.formatDate(quoteDate, true)"
        :profile-pic="assignedPic"
      />
    </v-col>
    <v-col>
      <DataValue
        label="Closer"
        :value="assignedName"
        :class="{ 'red--text': assignedName === 'Unassigned'}"
        :user-id="assignedId"
        icon="account-circle"
        :extra-info="$helpers.formatDate(assignedDate, true)"
        :profile-pic="assignedPic"
      />
    </v-col>

    <v-col>
      <DataValue
        label="Disposition"
        :value="disposition ? disposition : 'No Disposition Found'"
        :class="getTextClass(positive, neutral, negative)"
        :extra-info="$helpers.formatDate(dispositionDateTime, true)"
      />
    </v-col>
    
  </v-row>
</template>

<script>
import DataValue from "./data-value"

export default {
  components: { DataValue },
  props: {
     quoteDate: {
      type: Number,
      default: () => 0
    },
     assignedDate: {
      type: Number,
      default: () => 0
    },
    fromName: {
      type: String,
      default: ""
    },
    assignedName: {
      type: String,
      default: ""
    },   
    assignedId: {
      type: Number,
      default: () => 0
    },
    fromId: {
      type: Number,
      default: () => 0
    },
     assignedPic: {
       type: String,
      default: ""
    },

    disposition: {
      type: String,
      defaul: null
    },

    dispositionID: {
      type: Number,
      default: null
    },

    negative: {
      type: Boolean,
      default: false,
    },

    positive: {
      type: Boolean,
      default: false,
    },

    neutral: {
      type: Boolean,
      default: false,
    },

    dispositionDateTime: {
      type: Number,
      default: 0
    }
    
  },

  methods: {
    getTextClass(positive, neutral, negative) {
      if(positive) {
        return 'success--text'
      }else if(negative) {
        return 'error--text'
      }else {
        return 'warning--text'
      }
    }
  }
}
</script>

<style></style>
